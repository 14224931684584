@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.contact-us-body {
  background-image: linear-gradient(
    179deg,
    #333 -24%,
    #2d3641 -24%,
    #173f71 99%
  );
}

.contact-us-card {
  border-radius: 17px;
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.contact-us-text {
  color: #173f71;
}

.contact-item {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.sender-contact {
  border-radius: 9px;
  border: solid 1px #3e3e3e;
  background-color: #faffff;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8b8b8b;
}

.sender-message {
  border: solid 1px #8b8b8b;
  background-color: #faffff;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8b8b8b;
}

.typing-image {
  border-radius: 13px;
}

.typing-image-backdrop {
  border-radius: 13px;
}

.typing-close-button {
  border-radius: 1000px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #173f71;
  color: #173f71;
}

.contact-us-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
}

.information-card {
  background-color: #faffff;
}

.faqs {
  background-color: #eaebeb;
}

.faqs-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #173f71;
}

.faq-line {
  margin: 13px 0 13px 0;
}

.faq-item {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #173f71;
}

.faq-body-text {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.faq-body-title {
  color: #5daaac;
  font-family: Arial;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
}

.information-card-item {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Arial;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b8b8b;
}

.info-card-title {
  font-family: "Noto Sans", sans-serif;
  color: #173f71;
  padding: 0 0 10px 0;
}

.contact-us-height {
  height: 50vh;
}

@media screen and (min-width: 768px) {
  .contact-us-height {
    height: 55vh;
  }
}

@media screen and (min-width: 1024px) {
  .contact-us-height {
    height: vh;
  }
}
