@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.header-bar {
  background-color: #fefefe;
}

.header-link-active {
  height: 22px;
  margin: 10px 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.header-link-active-underline {
  height: 2px;
  border-radius: 1000px;
  border: solid 1px #173f71;
  background-color: #1c2126;
}

.header-link-inactive {
  height: 22px;
  margin: 10px 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8b8b8b;
}

.header-link-inactive-underline {
  height: 2px;
  border-radius: 1000px;
  border: solid 1px transparent;
  background-color: transparent;
}

.header-container {
  height: 120px;
  padding: 0.1px 51px 41px 0.1px;
}

.logo-header {
  width: 99.5px;
  height: 29.6px;
}

.mobile-logo-header {
  height: 48.8px;
}

.header-search {
  height: 32px;
  color: #173f71;
  border-radius: 24px;
  border: solid 2px #173f71;
  background-color: #fff;
  outline: none;
}

.upload-button {
  text-align: center;
  font-size: 12px;
  border: solid 1px black;
  font-family: "Noto Sans", sans-serif;
}

.input-title {
  color: #173f71;
  font-weight: bold;
  font-family: "Noto Sans", sans-serif;
}

.admin-page-title {
  color: #173f71;
  font-weight: bold;
  font-family: "Noto Sans", sans-serif;
}

.create-button {
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #4b6fed;
  padding: 5px 8px;
  font-family: "Noto Sans", sans-serif;
}

.remove-button {
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #d34633;
  padding: 5px 8px;
  font-family: "Noto Sans", sans-serif;
}

.add-button {
  text-align: center;
  font-size: 12px;
  color: white;
  background-color: #173f71;
  padding: 5px 8px;
  font-family: "Noto Sans", sans-serif;
}

.select-button {
  height: 27px;
  text-align: center;
  font-size: 12px;
  background-color: #e7e2e2;
  padding: 5px 8px;
}

.filter-button {
  width: 187px;
  text-align: center;
  font-size: 12px;
  padding: 5px 8px;
}

.select-button-adv {
  width: 187px;
  text-align: center;
  font-size: 12px;
  background-color: #173f71;
  color: #fff;
  padding: 5px 8px;
}

.header-search::placeholder {
  color: #173f71;
}

.home-carousel-video {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.home-carousel-left-links {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  color: #fff;
}

.site-text-blue {
  color: #173f71;
  font-family: "Noto Sans", sans-serif;
}

.site-bg {
  background-color: #173f71;
}

.disabled-site-bg {
  background-color: #88adda;
}

.today-quote {
  filter: blur(16.5px);
  -webkit-filter: blur(16.5px);
  font-family: "Noto Sans", sans-serif;
}

.blured-bg {
  background-color: #361215;
  box-shadow: none;
}

.quote {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #faffff;
}

.quote-mob {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #faffff;
}

.quote-description {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #faffff;
}

.quote-description-mob {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #faffff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.quote-author {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #faffff;
}

.site-footer {
  text-decoration: none;
}

.page-title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
}

.playing-buttons {
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fcfcfc;
}

.page-header {
  padding-top: 80px;
  padding-bottom: 50px;
}

.page-header-mob {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.buttons {
  padding: 13px 38px;
  border-radius: 24px;
  color: #fff;
}

.social-media-links {
  width: 15px;
  height: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fcfcfc;
}

.social-media-links-no-cicle {
  width: 15px;
  height: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fcfcfc;
}

.mobile-menu-button {
  width: 60px;
  height: 40px;
  font-weight: bold;
  color: #173f71;
}

.close-mobile-menu-button {
  width: 60px;
  height: 40px;
  font-weight: bold;
  color: #173f71;
}

.mobile-menu-list {
  padding: auto;
}

.mobile-globe-button {
  width: 24.8px;
  height: 24.8px;
  font-weight: bold;
  color: #173f71;
}

.button-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 2px rgba(255, 255, 255, 0.22);
}

.social-media-button-circle {
  width: 30px;
  height: 30px;
  border-radius: 1000px;
  border: solid 2px #fff;
  background-color: rgba(252, 252, 252, 0);
}

.contact-button {
  background-color: #bf1e2e;
}

.site-default-height {
  height: 70vh;
}

.service-img-height {
  height: 40vh;
}

.simulation-height {
  height: 70vh;
}

.simulation {
  background: url("../src/images/bwbg.jpg") no-repeat;
  background-size: cover;
}

.home-about-img-height {
  height: 60vh;
}

@media screen and (min-width: 640px) {
  .site-default-height {
    height: 80vh;
  }
}

@media screen and (min-width: 768px) {
  .site-default-height {
    height: 80vh;
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1280px) {
  .site-default-height {
    height: 70vh;
  }
  .home-about-img-height {
    height: 40vh;
  }
}

@media screen and (min-width: 1536px) {
  .site-default-height {
    height: 75vh;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
