@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.home-services {
  background-color: #eaebeb;
}

.home-services-mob {
  background-color: #eaebeb;
}

.home-services-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.4px;
  text-align: center;
}

.service-title-info {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.training-title-info {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.service-description-info {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.4px;
  text-align: left;
  color: #737373;
}

.service-page-header {
  height: 367px;
  background-image: linear-gradient(to bottom, #173f71 27%, #333 143%);
}

.service-page-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  text-align: center;
  color: #f2f4f8;
}

.service-icon-cirlce {
  width: 48px;
  height: 48px;
  border-radius: 1000px;
  background-color: #f2f4f8;
}

.service-icon-name {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f5f5f5;
}

.icon-size {
  width: 20px;
  height: 20px;
  font-weight: bold;
}

.web-icon {
  width: 20px;
  height: 20px;
  padding: 0.7px 0 0.7px 0px;
  opacity: 0;
  background-color: rgba(253, 73, 198, 0.35);
}

.it-service {
  background-color: #fbfbfb;
}

.web-service {
  background-color: #eaebeb;
}

.elearning-service {
  background-color: #fbfbfb;
}

@media screen and(max-width: 640px) {
  .elearning-service {
    background-color: #ce4e4e;
  }
}

.networking-service {
  background-color: #eaebeb;
}

.simulation-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #3c3c3c;
}

.games-service {
  background-color: #fbfbfb;
}

.games-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.training-service {
  background-color: #eaebeb;
}

.interactive-service-upper {
  height: 900px;
  background-color: #fbfbfb;
}

.interactive-service-lower {
  height: 354px;
  margin: 506px 0 0;
  background-color: #eaebeb;
}

.interactive-service {
  height: 750px;
}

.interactive-service-title {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.interactive-service-body {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8b8b8b;
}


.interactive-pc-image {
  border-radius: 18px;
  border: solid 1px #707070;
  background-color: #260404;
}

.interactive-phone-image {
  border-radius: 18px;
  border: solid 1px #707070;
  background-color: #260404;
}

.interactive-phone-image-pub {
  /* width: 55vw;
  height: 27vh;
  padding: 27.5px;
  border-radius: 18px; */
  border: solid 1px #707070;
  background-color: #260404;
  box-shadow: 0 10px 20px 0 #5daaac;
}

.interactive-bio-bg {
  border: solid 1px #173f71;
  background-image: linear-gradient(
    to bottom,
    #5daaac,
    #ebac1a,
    rgba(23, 63, 113, 0.79)
  );
}

.interactive-pc-image-bottom {
  border: solid 1px #707070;
  background-color: #260404;
}

.service-title {
  font-weight: 600;
  line-height: 1.5;
  height: 60px;
}


.it-descriptor {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: left;
  color: #797979;
}

.it-testimony-title {
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
}

.it-testimony-description {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  text-align: left;
  color: #797979;
}

.client-name {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  line-height: 1.71;
  text-align: left;
  font-size: 20px;
}

.testimony-border {
  border-right: solid 2px gray;
}

.rounding-image {
  border-radius: 10px;
}

.web-service-text {
  font-family: Arial;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.service-images-grp {
  width: 90vw;
}

.service-image-rounded {
  border-radius: 10px;
}

.elearning-text {
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.4px;
  text-align: center;
}

.networking-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.training-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #797979;
}

.interactive-phone-height{
  width: 30vw;
  height: 40vh;
}

@media screen and (min-width: 768px) {
  .interactive-phone-image-pub {
    /* width: 25vw;
    height: 27vh;
    padding: 27.5px;
    border-radius: 18px; */
    border: solid 1px #707070;
    background-color: #260404;
    box-shadow: 0 10px 20px 0 #5daaac;
  }
  .interactive-service {
    height: 550px;
  }
}

@media screen and (min-width: 1024px) {
  .interactive-pc-image {
    /* width: 75vw;
    height: 40vh;
    padding: 27.5px 26px 45.5px 27.5px; */
    border-radius: 18px;
    border: solid 1px #707070;
    background-color: #260404;
  }
  .interactive-pc-image-bottom {
    /* width: 85vw;
    height: 3vh; */
    border: solid 1px #707070;
    background-color: #260404;
  }
  .interactive-service {
    height: 750px;
  }
}

@media screen and (min-width: 1440px) {
  .interactive-pc-image {
    /* width: 65vw;
    height: 50vh;
    padding: 27.5px 26px 45.5px 27.5px; */
    border-radius: 18px;
    border: solid 1px #707070;
    background-color: #260404;
  }
  .interactive-pc-image-bottom {
    /* width: 55vw;
    height: 3vh; */
    border: solid 1px #707070;
    background-color: #260404;
  }
  .interactive-service {
    height: 857px;
  }
}

