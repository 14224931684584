@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.home-partners {
  background-color: #fbfbfb;
}

.partners-name {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
}

.partners-logo {
  padding: 30px;
}

.partners-header {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #f2f4f8;
}

.partners-body {
  background-color: #eaebeb;
}

.partners-header-bg {
  text-align: center;
  background-image: linear-gradient(to bottom, #173f71 27%, #333 143%);
}

.partners-affiliation {
  background-color: #fbfbfb;
}

.partners-top-part {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  color: #173f71;
}

.partners-top-title {
  font-size: 40px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.partners-top-text {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

.affiliation-title {
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.partners-info-card {
}

.partner-logo-part {
  background-color: #faffff;
}

.partner-logo-part-text {
  font-family: Georgia;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #173f71;
}

.partner-details {
  text-align: left;
}

.partner-name {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #173f71;
}

.partner-detail-text-top {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #173f71;
}

.partner-detail-text-bottom {
  font-family: Georgia;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #173f71;
}

.affiliators-info {
}

.affiliator-card {
  height: 620px;
  width: 360px;
  padding: 10px;
}

.affiliator-image {
  height: 360px;
  width: 340px;
}

.affiliator-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #173f71;
}

.affiliator-text {
  padding: 21px 44px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: #173f71;
}

.affiliation-small-text {
  font-family: Arial;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.affiliator-details {
  padding: 41px 0 43px 0;
  box-shadow: 10px 10px 40px 0 #173f71;
  background-color: #bce0fd;
  text-align: center;
}

.partner-logo-part-image {
}
