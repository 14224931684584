.home-spotlight {
  height: 572px;
  padding-top: 70px;
}
.spotlight-playing-buttons {
  width: 56px;
  height: 56px;
}

.spotlight-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #f1f9ff;
}

.spotlight-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f1f9ff;
}
