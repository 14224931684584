@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.products-page-header {
  background-image: linear-gradient(to bottom, #173f71 27%, #333 143%);
}

.products-page-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #f2f4f8;
}

.product-name {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #173f71;
}

.video-games {
  background-color: #fbfbfb;
}

.margin-down-left {
  padding: 13px 43px 0 0;
}

.margin-down-right {
  padding: 13px 43px 0 0;
}

.video-games-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.game-available {
  font-family: Helvetica;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.neza-digital {
  background-color: #faffff;
}

.neza-digital-body {
  text-align: right;
}

.neza-digital-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #797979;
}

.swiftmonitor {
  background-color: #fbfbfb;
}

.swiftmonitor-body {
}

.swiftmonitor-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.ogenius-panda {
  background-color: #faffff;
}

.ogenius-panda-body {
  background-color: #faffff;
}

.ogenius-panda-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.ogenius-mail {
  background-color: #eaebeb;
}

.ogenius-mail-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #173f71;
}

.email-card {
  width: 280px;
  padding: 53px 0 39px;
  height: 550px;
  border-radius: 23px;
  box-shadow: 0 20px 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #fff;
  background-image: linear-gradient(181deg, #5daaac 96%, #173f71 0%);
}

.email-info {
  padding: 49px 70px 48px 95px;
}

.email-main-info-bg {
  opacity: 0.3;
  background-image: linear-gradient(261deg, #5daaac 52%, #173f71 50%);
}

.email-main-info {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.email-type {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.email-cost {
  font-family: Arial;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.email-currency {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 50%;
}

.email-period {
  margin: 1px 0 5px 3px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.boolax {
  background-color: #fbfbfb;
}

.boolax-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.boolax-body {
}

.product-img-height {
  height: 35vh;
}

.product-img-sm-height {
  height: 27vh;
}

.product-img-lg-height {
  height: 50vh;
}

.opanda-img-height {
  height: 65vh;
}

@media screen and (min-width: 1024px) {
  .product-img-lg-height {
    height: 35vh;
  }
  .opanda-img-height {
    height: 35vh;
  }
}