@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');

.home-footer {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-image: linear-gradient(125deg, #173f71 50%, #bf1e2e 108%),
    url('../images/cogwheel.svg');
  background-blend-mode: screen;
  color: #fff;
  font-family: 'Noto Sans', sans-serif;
}

.footer-item-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.add-circle {
  color: #fff;
  font-weight: bold;
}

.footer-items-list {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.bootom-footer {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.39px;
  text-align: center;
  background-color: #ebebeb;
}
