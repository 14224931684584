@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.ph {
}

.ph-publications {
  filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: #173f71;
}

.ph-publications-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #f2f4f8;
}

.big-title {
  background-color: #fff;
}

.bgt-body {
}

.pis-body {
  background-image: linear-gradient(67deg, #173f71 -2%, #bf1e2e 132%);
}

.pis-part {
}

.pis-bottom-triangle {
  border-bottom: 100px solid yellow;
  border-right: 100px solid transparent;
}

.pis {
  background-color: #f1f9ff;
}

.featured {
  font-family: "Noto Sans", sans-serif;
}

.pis-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bf1e2e;
}

.pis-add-button {
  width: 30px;
  height: 30px;
  padding: 7px;
  color: #5daaac;
}

.pis-sentence {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #5daaac;
}

.pis-by {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5daaac;
}

.sandra-holmes {
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #173f71, #bf1e2e);
}

.sh-title {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fff;
}

.sh-big-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fff;
}

.sh-text {
  font-family: Arial;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.sh-cost-info {
  background-color: #e8ad26;
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #173f71;
}

.sh-staff-photo {
  border-radius: 1000px;
}

.sh-staff-name {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.sh-staff-title {
  font-family: Arial;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.story-one-left {
  background-image: linear-gradient(to bottom, #5daaac, #173f71);
}

.sol-story-number {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #fff;
}

.sol-bg-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.sol-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fff;
}

.story-one-right-top {
  background-color: #eaebeb;
}

.sort-story-number {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #3c3c3c;
}

.sort-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3c3c3c;
}

.sort-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #8b8b8b;
}

.story-one-right-bottom {
  background-color: #e8ad26;
}

.sorb-story-number {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #333;
}

.sorb-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.sorb-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.today {
  background-color: #eaebeb;
}

.gift-card {
}

.gift-card-title {
  background-image: linear-gradient(to bottom, #bf1e2e, #e8ad26);
}

.gifts-frebbies {
  background-color: #fff;
}

.story-number {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #173f71;
}

.lbg-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 33px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #173f71;
}

.lsm-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #173f71;
}

.pub-top-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.today-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #173f71;
}

.today-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #173f71;
}

.gifts-title {
  font-family: "Noto Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #173f71;
}

.top-gifts {
}

.bottom-gifts {
  float: right;
}

.pub-list {
  text-align: center;
}

.pub-list li {
  display: inline-block;
  width: 200px;
  font-family: Arial;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5daaac;
  position: relative;
  text-align: start;
}

.in-circle {
  width: 6px;
  height: 6px;
  margin: 0 50px 32px 0px;
  background-color: #e8ad26;
  border-radius: 1000px;
}

.pub-list li::after {
  content: "";
  background: #5daaac;
  height: 2px;
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  bottom: 45px;
  z-index: -1;
}

.star {
  width: 0;
  height: 0;
  color: #173f71;
  border-right: 12.5px solid transparent;
  border-bottom: 8.75px solid #173f71;
  border-left: 12.5px solid transparent;
  transform: rotate(35deg);
  position: relative;
  display: block;
}

.star:after {
  border-right: 3.75px solid transparent;
  border-bottom: 10px solid #173f71;
  border-left: 3.75px solid transparent;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -5.625px;
  left: -8.125px;
  display: block;
  transform: rotate(-35deg);
}

.star:before {
  color: hsl(213, 66%, 27%);
  border-right: 12.5px solid transparent;
  border-bottom: 8.75px solid #173f71;
  border-left: 12.5px solid transparent;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 0.375px;
  left: -13.875px;
  display: block;
  transform: rotate(-70deg);
}

.star-un-rated {
  width: 0;
  height: 0;
  color: #7f7f7f;
  border-right: 12.5px solid transparent;
  border-bottom: 8.75px solid #7f7f7f;
  border-left: 12.5px solid transparent;
  transform: rotate(35deg);
  position: relative;
  display: block;
}

.star-un-rated:before {
  color: #7f7f7f;
  border-right: 12.5px solid transparent;
  border-bottom: 8.75px solid #7f7f7f;
  border-left: 12.5px solid transparent;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 0.375px;
  left: -13.875px;
  display: block;
  transform: rotate(-70deg);
}

.star-un-rated:after {
  border-right: 3.75px solid transparent;
  border-bottom: 10px solid #7f7f7f;
  border-left: 3.75px solid transparent;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -5.625px;
  left: -8.125px;
  display: block;
  transform: rotate(-35deg);
}

.pis-height {
  height: 50vh;
}

.gift-img-height {
  height: 28vh;
}

.hands-up-height{
  height: 35vh;
}

.today-img-height{
  height: 35vh;
}

@media screen and (min-width: 768px) {
  .pis-height {
    height: 75vh;
  }
}