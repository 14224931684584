@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.site-font-family {
  font-family: "Noto Sans", sans-serif;
}

.header-menu {
  height: 60px;
  padding-left: 52px;
  background: #173f71;
}

.header-menu-titles {
  color: #000000;
  text-align: center;
  font-weight: bold;
  padding-right: 30px;
}

.side-menu {
  background: #fbfcfd;
  width: 260px;
  padding-top: 20px;
  margin-bottom: 0;
}

.site-bg {
  background: #173f71;
}

.copyright {
  background: #173f71;
  height: 42px;
  padding-left: 32px;
  padding-top: 11px;
  font-weight: bold;
}

.input-text {
  width: 217px;
  height: 27px;
  border: solid 1px black;
  background-color: #fff;
  outline: none;
}

.input-text-area {
  width: 217px;
  border: solid 1px black;
  background-color: #fff;
  outline: none;
}

.bg-analytic-data {
  background-color: #e7e2e2;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
