@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.carousel-text {
  background-color: #00050a;
  color: #fff;
}

.home-carousel-title {
  font-family: "Noto Sans", sans-serif;
  color: #fff;
}

.home-carousel-text {
  font-family: "Noto Sans", sans-serif;
  color: #fff;
}

.home-coursel {
  background-color: #173f71;
}
