@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");

.about-text-title {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.about-text-sub-title {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.about-text-body {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #797979;
}

.about-page-header {
  backdrop-filter: blur(5.1px);
  backdrop-filter: blur(5.1px);
}

.get-to-know-us {
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #f2f4f8;
}

.about-page-title {
  background-image: linear-gradient(to bottom, #173f71, #0c2039);
}

.executif-leadership {
  background-color: #faffff;
}

.executif-leaders {
}

.founded-on {
  font-family: Arial;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3c;
}

.executif-body {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.executif-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.executif-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #173f71;
}

.executif-leader-card {
  background-image: linear-gradient(170deg, #173f71 6%, #bf1e2e 131%);
}

.executif-leaders:nth-child(2) {
  width: 360px;
  height: 407px;
  padding: 40px 40px 82px;
  background-image: linear-gradient(170deg, #173f71 6%, #bf1e2e 131%);
}

.executif-leader-md {
  background-image: linear-gradient(170deg, #173f71 6%, #bf1e2e 131%);
}

.executif-photo {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
}

.executif-photo-md {
  width: 80px;
  height: 80px;
  border-radius: 1000px;
}

.executif-name {
  padding-top: 23px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.executif-leader-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding-top: 27px;
}

.careers {
}

.careers-body {
}

.careers-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #173f71;
}

.careers-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.careers-bottom-text {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #3c3c3c;
}

.staff-team {
  background-color: #f1f9ff;
}

.staff-team-body {
}

.staff-team-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #173f71;
}

.staff-team-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #797979;
}

.staff-team-bottom-text {
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #173f71;
}

.staff-card {
  opacity: 1;
  border-radius: 20px;
  background-color: #173f71;
}

.staff-card-img {
  opacity: 1;
  border-radius: 20px;
  background-color: #173f71;
}

.st-card-img {
  border-radius: 20px;
}

.staff-name {
  opacity: 1;
  font-family: Arial;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.staff-position {
  opacity: 1;
  font-family: Arial;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.awards-recognition {
  background-color: #eaebeb;
}

.awards-recognition-title {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 0.45px;
  color: #173f71;
}

.recognition-image2 {
}

.awards-recognition-text {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.4px;
  color: #737373;
}

.ethics-compliance {
  background-color: #fff;
}

.compliance-body {
  background-color: #bf1e2e;
}

.ethics-compliance-title {
  font-family: "Noto Sans", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.ethics-compliance-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.ethics-compliance-bottom-text {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #173f71;
}

.compliance-title {
  padding-bottom: 26px;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #faffff;
}

.compliance-text {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #faffff;
}

.work-with-you {
  filter: blur(7.7px);
  backdrop-filter: blur(7.7px);
  background-color: #173f71;
}

.work-with-you-body {
  color: #fff;
  font-family: "Noto Sans", sans-serif;
}

.work-with-you-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.4px;
  color: #fff;
}

.work-with-you-title {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 0.45px;
  color: #fff;
}

.about-hd-title {
  height: 30vh;
}

.career-img-height {
  height: 50vh;
}

.compliance-left-img-height {
  height: 30vh;
}

.compliance-img-height {
  height: 30vh;
}
